export default {
	login: {
		slogan: "高性能 / 精致设计 / 优雅体验 / 灵活问卷设计 ",
		describe:
			"采用最新的前端技术栈Vue 3与Element Plus组件库构建，确保问卷页面加载迅速，交互流畅，为大量用户同时参与调查提供坚实的性能保障。",
		signInTitle: "用户登录",
		accountLogin: "账号登录",
		mobileLogin: "手机号登录",
		rememberMe: "24小时免登录",
		forgetPassword: "忘记密码",
		signIn: "登录",
		signInOther: "其他登录方式",
		userPlaceholder: "用户名 / 手机 / 邮箱",
		userError: "请输入用户名",
		PWPlaceholder: "请输入密码",
		PWError: "请输入密码",
		admin: "管理员",
		user: "用户",
		mobilePlaceholder: "手机号码",
		mobileError: "请输入手机号码",
		smsPlaceholder: "短信验证码",
		smsError: "请输入短信验证码",
		smsGet: "获取验证码",
		smsSent: "已发送短信至手机号码",
		noAccount: "还没有账号?",
		createAccount: "创建新账号",
		wechatLoginTitle: "二维码登录",
		wechatLoginMsg: "请使用微信扫一扫登录 | 模拟3秒后自动扫描",
		wechatLoginResult: "已扫描 | 请在设备中点击授权登录",
	},
	user: {
		dynamic: "近期动态",
		info: "个人信息",
		settings: "设置",
		nightmode: "黑夜模式",
		nightmode_msg: "适合光线较弱的环境，当前黑暗模式为beta版本",
		language: "语言",
		language_msg: "翻译进行中，暂翻译了本视图的文本",
	},
};
