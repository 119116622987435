
import { ref } from 'vue'
const current = ref(JSON.parse(localStorage.getItem('system_current')))
const list = ref(JSON.parse(localStorage.getItem('system_list')))
export default function useGlobalData() {
    const setList = (data) => {
        list.value = data
        localStorage.setItem('system_list', JSON.stringify(data))
    }
    const setCurrent = (data) => {
        current.value = data
        localStorage.setItem('system_current', JSON.stringify(data))
    }
    return {
        setList,
        setCurrent,
        list,
        current
    }
}
