export default {
	login: {
		slogan: "High performance / delicate / grace",
		describe:
			"Vue3 + element plus based front-end solutions in the background.",
		signInTitle: "Sign in",
		accountLogin: "Account sign in",
		mobileLogin: "Mobile sign in",
		rememberMe: "Remember me",
		forgetPassword: "Forget password",
		signIn: "Sign in",
		signInOther: "Sign in with",
		userPlaceholder: "user / phone / email",
		userError: "Please input a user name",
		PWPlaceholder: "Please input a password",
		PWError: "Please input a password",
		admin: "Administrator",
		user: "User",
		mobilePlaceholder: "Mobile",
		mobileError: "Please input mobile",
		smsPlaceholder: "SMS Code",
		smsError: "Please input sms code",
		smsGet: "Get SMS Code",
		smsSent: "SMS sent to mobile number",
		noAccount: "No account?",
		createAccount: "Create a new account",
		wechatLoginTitle: "QR code sign in",
		wechatLoginMsg:
			"Please use wechat to scan and log in | Auto scan after 3 seconds of simulation",
		wechatLoginResult:
			"Scanned | Please click authorize login in the device",
	},
	user: {
		dynamic: "Dynamic",
		info: "User Info",
		settings: "Settings",
		nightmode: "night mode",
		nightmode_msg:
			"Suitable for low light environment,The current night mode is beta",
		language: "language",
		language_msg:
			"Translation in progress,Temporarily translated the text of this view",
	},
};
