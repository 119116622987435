import config from "@/config";
import http from "@/utils/request";

export default {
	menu: {
		myMenus: {
			url: `${config.API_URL}/system/menu/my/1.6.1`,
			name: "获取我的菜单",
			get: {
				code: 200,
				data: {
					menu: [
						//todo
						// {
						// 	meta:{
						// 		icon:"el-icon-home-filled",
						// 		title:"首页",
						// 		type:"menu"
						// 	},
						// 	name:"home",
						// 	path:"/home",
						// 	children:[
						// 		{
						// 			name: "dashboard",
						// 			path: "/dashboard",
						// 			meta: {
						// 				title: "任务控制台",
						// 				icon: "el-icon-operation",
						// 				affix:true
						// 			},
						// 			component: "home"
						// 		},
						// 		{
						// 			name: "userCenter",
						// 			path: "/usercenter",
						// 			meta: {
						// 				title: "帐号信息",
						// 				icon: "el-icon-user",
						// 				tag: "NEW"
						// 			},
						// 			component: "userCenter"
						// 		}
						// 	]
						// },
						// {
						// 	meta: {
						// 		icon: "el-icon-home-filled",
						// 		title: "首页",
						// 		type: "menu",
						// 	},
						// 	name: "dashboard",
						// 	path: "/dashboard",
						// 	component: "home",
						// },

						{
							meta: {
								icon: "el-icon-menu",
								title: "系统管理",
								type: "systemManagement",
							},
							name: "systemManagement",
							path: "/systemManagement",
							children: [
								{
									name: "systemManagement",
									path: "/systemManagement",
									meta: {
										title: "系统列表",
										icon: "el-icon-operation",
									},
									component: "systemManagement",
								},
							],
						},
						{
							meta: {
								icon: "el-icon-connection",
								title: "线索管理",
								type: "thread",
							},
							name: "thread",
							path: "/thread",
							children: [
								{
									name: "thread",
									path: "/thread",
									meta: {
										title: "线索列表",
										icon: "el-icon-memo",
									},
									component: "thread",
								},
							],
						},
						{
							meta: {
								icon: "el-icon-share",
								title: "参数管理",
								type: "parameter",
							},
							name: "parameter",
							path: "/parameter",
							children: [
								{
									name: "parameter",
									path: "/parameter",
									meta: {
										title: "参数分类",
										icon: "el-icon-operation",
									},
									component: "parameter",
								},
								{
									name:"parameter-single",
									path: "/parameter/parameter-single",
									meta: {
										title: "参数列表",
										icon: "el-icon-operation",
									},
									component: "parameter/parameter-single",
								},
							],
						},
						// {
						// 	meta: {
						// 		icon: "el-icon-medal",
						// 		title: "配置管理",
						// 		type: "configuration",
						// 	},
						// 	component: "configuration",
						// 	name: "configuration",
						// 	path: "/configuration",
						// },
						{
							meta: {
								icon: "el-icon-brush",
								title: "特征管理",
								type: "characterization",
							},
							component: "characterization",
							name: "characterization",
							path: "/characterization",
							children: [
								{
									name: "list",
									path: "/characterization-list",
									meta: {
										title: "特征列表",
										icon: "el-icon-operation",
									},
									component:
										"characterization/characterization-list",
								},
							],
						},
						{
							meta: {
								icon: "el-icon-operation",
								title: "题库管理",
								type: "questionBank",
							},
							component: "questionBank",
							name: "questionBank",
							path: "/questionBank",
							children: [
								{
									name: "questionBank-classify",
									path: "/questionBank/questionBank-classify",
									meta: {
										title: "题型分类",
										icon: "el-icon-operation",
									},
									component:
										"questionBank/questionBank-classify",
								},
								{
									name: "questionBank-select",
									path: "/questionBank/questionBank-select",
									meta: {
										title: "题型单项",
										icon: "el-icon-operation",
									},
									component:
										"questionBank/questionBank-select",
								},
								{
									name: "answer-process",
									path: "/questionBank/answer-process",
									meta: {
										title: "答题流程",
										icon: "el-icon-question-filled",
									},
									component: "questionBank/answer-process",
								},
							],
						},
						{
							meta: {
								icon: "el-icon-briefcase",
								title: "影响管理",
								type: "influence",
							},
							name: "influence",
							path: "/influence",
							children: [
								{
									name: "influence",
									path: "/influence",
									meta: {
										title: "影响类目",
										icon: "el-icon-operation",
									},
									component: "influence",
								},
								{
									name: "influence-single",
									path: "/influence/influence-single",
									meta: {
										title: "影响单项",
										icon: "el-icon-operation",
									},
									component: "influence/influence-single",
								},
							],
						},
						{
							meta: {
								icon: "el-icon-promotion",
								title: "报告管理",
								type: "report",
							},
							name: "report",
							path: "/report",
							children: [
								{
									name: "report",
									path: "/report",
									meta: {
										title: "报告类型",
										icon: "el-icon-operation",
									},
									component: "report",
								},
								{
									name: "report-single",
									path: "/report/report-single",
									meta: {
										title: "报告单项",
										icon: "el-icon-operation",
									},
									component: "report/report-single",
								},
							],
						},
					],
				},
				message: "",
			},
			// get: async function(){
			// 	return await http.get(this.url);
			// }
		},
		list: {
			url: `${config.API_URL}/system/menu/list`,
			name: "获取菜单",
			get: async function () {
				return await http.get(this.url);
			},
		},
	},
	dic: {
		tree: {
			url: `${config.API_URL}/system/dic/tree`,
			name: "获取字典树",
			get: async function () {
				return await http.get(this.url);
			},
		},
		list: {
			url: `${config.API_URL}/system/dic/list`,
			name: "字典明细",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		get: {
			url: `${config.API_URL}/system/dic/get`,
			name: "获取字典数据",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
	},
	role: {
		list: {
			url: `${config.API_URL}/system/role/list2`,
			name: "获取角色列表",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
	},
	dept: {
		list: {
			url: `${config.API_URL}/system/dept/list`,
			name: "获取部门列表",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
	},
	user: {
		list: {
			url: `${config.API_URL}/system/user/list`,
			name: "获取用户列表",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
	},
	app: {
		list: {
			url: `${config.API_URL}/system/app/list`,
			name: "应用列表",
			get: async function () {
				return await http.get(this.url);
			},
		},
	},
	log: {
		list: {
			url: `${config.API_URL}/system/log/list`,
			name: "日志列表",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
	},
	table: {
		list: {
			url: `${config.API_URL}/system/table/list`,
			name: "表格列管理列表",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
		info: {
			url: `${config.API_URL}/system/table/info`,
			name: "表格列管理详情",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
	},
	tasks: {
		list: {
			url: `${config.API_URL}/system/tasks/list`,
			name: "系统任务管理",
			get: async function (params) {
				return await http.get(this.url, params);
			},
		},
	},
};
